<template>
	<div class="main" v-loading="loading">
		<div class="mian-center">
			<div class="top">
				<div class="title">
					<div>CEI考核结果监控表</div>
				</div>
				<div class="flex">
					<div class="top-row1 flex">
						<div class="lable1">1239</div>
						<div class="lable2">RTS项目总数(个）</div>
					</div>
					<div class="top-row2 flex">
						<div class="statistics1 flex">
							<div class="box1">
								<div class="num1">531</div>
								<div class="description1">优秀项目</div>
							</div>
						</div>
						<div class="statistics2 box2 flex">
							<div class="box2">
								<div class="num2">708</div>
								<div class="description2">待关注项目</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="chaxu flex">
					<div class="button flex">
						<div class="all" @click="sendParameter(1)" :class="{current:active == 1}">全部项目</div>
						<div class="part" @click="sendParameter(2)" :class="{current:active == 2}">待关注项目</div>
					</div>
					<el-form ref="form" :model="form" label-width="100px" class="flex">
						<el-form-item>
							<el-input v-model="form.name" placeholder="请输入项目名称"></el-input>
							<el-button type="primary" @click="search" class="subtn">搜索</el-button>
							<el-button type="primary" @click="yjMap" class="subtn">业绩地图</el-button>
							<el-button type="primary" @click="toOtherpage" class="subtn">通讯总览</el-button>
							<!-- <el-button type="primary" @click="toAPP" class="subtn">APP</el-button> -->
						</el-form-item>
					</el-form>
				</div>
				<div class="main-table">
					<el-table :data="newArry" class="table-h"
						style="width: 100%；font-family: PingFang-SC-Regular;font-size:1vw" height="32vh"
						@row-click="openDetails" :row-class-name="tableRowClassName" fixed>
						<el-table-column label="序号" align="center" width="55">
							<template slot-scope="scope">{{ scope.$index + 1 }}</template>
						</el-table-column>
						<el-table-column prop="project_name" label="项目名称" align="center">
						</el-table-column>
						<el-table-column label="通讯状态" align="center" width="100">
							<template slot-scope="scope">
								<div class="online" :style="{background: scope.row.online ? '#33c412' : 'red'}"
									style="border-radius: 5px;font-size:13px">
									{{scope.row.online ? '在线' : '断开'}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="cei" align="center" width="120" label="CEI结果">
						</el-table-column>
						<el-table-column prop="place" align="center" label="项目所在地">
						</el-table-column>
						<el-table-column prop="engineer" align="center" width="120" label="项目经理">
						</el-table-column>
						<el-table-column prop="phone" align="center" label="联系方式">
						</el-table-column>
					</el-table>
					<el-pagination class="jiange" @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import * as echarts from "echarts";
	require("echarts/theme/macarons"); //引入主题
	import {
		authProjectlist
	} from "@/api/otherview/index" //用户有权查看的列表
	import Format from "../../utils/auth.js"
	import {
		ceilist
	} from "@/api/otherview/index" //CEI统计结果项目列表
	import {
		panelList
	} from "@/api/otherview/index" //面板统计
	import global from '@/utils/ws.js'
	// deletList
	export default {
		data() {
			return {
				count: '',
				nice: '',
				bad: '',
				active: 1, //默认选择
				currentPage: 1, //初始页
				PageSize: 6, //每页的数据
				totalCount: 1239,
				loading: false,
				pageSizes: [6, 10,20, 30, 40, 50, 100],
				form: {
					name: '',
				},
				config: {},
				tableData: [],
				newArry: []
			}
		},
		watch: {

		},
		created() {
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.gethisInfo()
		},
		mounted() {
			// this.global.localSocket();
		},
		ws: {},
		methods: {
			// 打开业绩地图
			yjMap() {
				this.$router.push({
					path: '/show'
				})
			},
			// 打开通讯总览
			toOtherpage() {
				this.$router.push({
					path: '/userotherPage_zt'
				});
			},
			// toAPP() {
			// 	window.open('mstsc')
			// },
			// button按钮单选
			sendParameter(value) {
				this.active = value;
				if (value == 1) {
					this.currentPage = 1
					this.gethisInfo()
				} else {
					this.currentPage = 1
					let arr = this.tableData.filter(item => item.nice == false)
					this.getResultsData()
				}
			},
			// 搜索
			search() {
				this.gethisInfo()
			},
			handleSizeChange(val) {
				this.PageSize = val
				this.getResultsData()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getResultsData()
			},
			gethisInfo() {
				ceilist({
						"project_name": this.form.name,
					})
					.then(res => {
						this.count = res.count
						this.nice = res.nice
						this.bad = res.bad
						this.tableData = res.list
						// console.log(this.form.name, "点击一次")
						this.getResultsData()
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			//前端自己分页
			getResultsData() {
				// this指向改一下
				let that = this;
				let list = that.tableData; //后端回来表格的数据
				//渲染的数据newArry赋值
				this.newArry = list.filter(
					(item, index) =>
					index < that.currentPage * that.PageSize &&
					index >= that.PageSize * (that.currentPage - 1)
				); //根据页数显示相应的内容
				// this.totalCount = list.length;
				this.totalCount = 1239;
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.nice == false) {
					return 'daiguanzhu'
				}
			},
			// panelList
			// 打开不同的项目类型
			openDetails(row) {
				
				// sessionStorage.setItem("deviceInfo", JSON.stringify({type:'rfl'}))
				localStorage.setItem("deviceType", JSON.stringify({
					type: row.product_type,
					device: row.device_name,
					project: row.project_name
				}))
				let authinfo = JSON.parse(localStorage.getItem("authInfo"))
				localStorage.removeItem('spotArr')
				let result = authinfo.filter(item => item.id === row.device_name + '_r')
				if (result.length == '1') {
					// console.log(result.length)
					let autharr = []
					for (let i in authinfo) {
						if (authinfo[i].pid === row.device_name) {
							autharr.push(authinfo[i].id)
						}
					}
					localStorage.setItem("autharr", JSON.stringify(autharr))
					switch (row.product_type) {
						case 'rfl':
							return this.$router.push({
								path: '/index'
							});
						case 'jrl':
							return this.$router.push({
								path: '/Jrindex'
							});
						case 'rql':
							return this.$router.push({
								path: '/Rqindex'
							});
						case 'cfb':
							return this.$router.push({
								path: '/Cfbindex'
							});
						case 'mfl':
							return this.$router.push({
								path: '/Mfindex'
							});
					}
				}else {
					this.$message.success("您没有WEB访问的权限");
				}
			},
		}
	}
</script>
<style scoped lang="scss">
	.addform {
		padding: 0 2.5vw;
	}

	.main {
		width: 100vw;
		margin-left: 0vw;
		height: 92vh;
		background-color: #132735;

		// border:#168db2 1px solid;
		.mian-center {
			width: 90vw;
			height: 86vh;
			margin-left: 5vw;
			background-color: #071c2b;
			box-shadow: 0vh 1vh 2vh 0vh rgba(83, 93, 113, 0.06);
			border-radius: 1vh;
			opacity: 0.95;
		}

		.chaxu {
			margin-top: 4vh;
			height: 7vh;
			width: 90vw;
			line-height: 5vh;

			.button {
				width: 14.58vw;
				height: 3.89vh;
				font-size: 1.04vw;
				line-height: 2.04vw;
				letter-spacing: 0vw;
				color: #6acafb;
				text-align: center;
				margin-left: 2.08vw;
				background-color: #0c202f;
				border-radius: 0.19vh;
				border: solid 0.09vh #183f56;

				.all {
					width: 7.29vw;
					height: 3.89vh;
					cursor: pointer;
					opacity: 0.5;
					border-right: solid 0.09vh #183f56;
				}

				.part {
					width: 7.29vw;
					opacity: 0.5;
					cursor: pointer;
				}

				.current {
					opacity: 1;
				}
			}

		}

		.chaxu-row1 {
			width: 8vw;
			height: 2vh;
			line-height: 5vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #6acafb;
		}

		.top {
			height: 22vh;
			width: 90vw;
			//  border:#168db2 1px solid;
		}

		.title {
			text-align: center;
			font-family: PingFang-SC-Regular;
			font-size: 1.88vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vw;
			letter-spacing: 0vw;
			color: #ffffff;
		}

		.top-row1 {
			height: 18vh;
			width: 24vw;
			margin-top: 1vh;
			background-color: #004b6e20;
			border-radius: 0vh;
			// opacity: 0.2;
			margin-left: 6vw;

			.lable1 {
				width: 8vw;
				height: 18vh;
				font-size: 8vh;
				line-height: 18vh;
				letter-spacing: 0vh;
				color: #ffffff;
				// margin-top:5vh;
				margin-left: 2vw;
			}

			.lable2 {
				width: 13vw;
				height: 18vh;
				font-family: PingFang-SC-Regular;
				font-size: 1.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18vh;
				letter-spacing: 0vh;
				margin-top: 5vh;
				margin-left: 1vw;
				color: #6acafb;
			}
		}

		.top-row2 {
			width: 50.63vw;
			height: 18vh;
			margin-top: 2vh;
			background-color: #004b6e20;
			border-radius: 0vh;
			margin-left: 3.96vw;
			font-family: PingFang-SC-Regular;
			font-size: 0.83vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2.5vw;
			letter-spacing: 0vw;
			color: #6acafb;

			.statistics1 {
				width: 16.82vw;
				height: 15.19vh;
				margin-left: 7.45vw;
				margin-top: 2.04vh;
				background-image: url("~@/assets/images/project1.png");
				background-size: 100% 100%;

				.box1 {
					width: 5vw;
					margin-top: 4.41vh;
					margin-left: 9.43vw;
					line-height: 4.44vh;

					.num1 {
						height: 2.59vh;
						font-size: 1.88vw;
					}

					.description1 {
						height: 1.39vh;
					}
				}
			}

			.statistics2 {
				width: 16.82vw;
				height: 15.19vh;
				margin-left: 3.23vw;
				margin-top: 2.04vh;
				background-image: url("~@/assets/images/project2.png");
				background-size: 100% 100%;

				.box2 {
					width: 5vw;
					margin-top: 4.41vh;
					margin-left: 9.43vw;
					line-height: 4.44vh;

					.num2 {
						height: 2.59vh;
						font-size: 1.88vw;
						color: #faa75d;
					}

					.description2 {
						height: 1.39vh;
					}
				}
			}

		}

		.fl {
			float: left;
		}

	}

	::v-deep {
		.daiguanzhu {
			td {
				background: #168db2 !important;
			}
		}

		.el-table::before {
			background-color: #071c2b !important;
		}

		.el-pager li.active {
			color: #409EFF;
			cursor: default;
		}

		.el-form-item__label:before {
			content: none !important;
		}

		.el-button--small {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.addPro {
			background-image: url("~@/assets/images/rfl_switch.png");
			background-size: 100% 100%;
			height: 78vh;

			.el-dialog__header {
				display: none;
			}

			.el-dialog__body {
				padding: 0px;
			}

			.addBox {
				.evaluate-header {
					height: 7vh;

					// background: #5DCEF3;
					line-height: 6vh;
					//  background: #58cdf4;
					//  line-height: 50px;
					text-align: center;
					color: #0ef5fd;
					font-size: 3vh;
					position: relative;
					border-radius: 5px 5px 0 0;

					.evaluate-header-icon {
						width: 2vw;
						height: 2vh;
						position: absolute;
						right: 1vw;
						margin-top: -6vh;
						cursor: pointer;
					}

				}

				.flex {
					margin-top: 5vh;
				}

				.el-input {
					margin-bottom: 3vh;
				}

				.btn {
					width: 8vw;
					height: 5vh;
					background-color: #168db2;
					border-radius: 0.1vh;
					border: none;
					color: #fff;
					margin: 2vh auto;
					text-align: center;
					line-height: 5vh;
					cursor: pointer;
				}
			}
		}

		.el-icon-arrow-up {
			line-height: 20px !important;
		}

		.el-form-item {
			display: inline-block !important;
		}

		.el-form-item__label {
			width: 150px;
			color: #168db2;
		}

		.el-input {
			width: 15vw;
			height: 5vh;
		}

		.el-input__inner {
			background-color: #0c202f;
			height: 30px;
			border: 0.1px solid #168db2;
			color: #fff;
		}

		.main-table {
			height: 62vh;
			width: 88vw;
			margin-top: 0vh;
			font-family: PingFang-SC-Regular;
		}

		.jiange {
			margin-top: 0vh;
			background-color: #0c202f;
		}

		.table-h {
			height: 45vh;
			margin-left: 1vw;
			background-color: #0c202f;
			font-family: PingFang-SC-Regular;

			th {
				padding: 0 !important;
				height: 4vh;
				line-height: 4vh;
				background-color: #183f54;
				color: #6acafb;
				font-size: 2vh;
				font-family: PingFang-SC-Regular;
			}

			td {
				padding: 0 !important;
				height: 4vh;
				line-height: 4vh;
				background-color: #0c202f;
				color: #fff;
			}
		}

		.el-table td,
		.el-table th.is-leaf,
		.el-table--border,
		.el-table--group {
			border-color: #183f56;
			border-right: 1px solid #183f56;
		}

		.el-table__body {
			border-left: 1px solid #183f56;
		}

		.el-table--border::after,
		.el-table--group::after,
		.el-table::before {
			background-color: #183f56;
		}

		.el-table tbody tr:hover>td {
			background-color: #168db2;
		}

		.subtn {
			width: 8vw;
			// height: 5vh;
			// line-height:5vh;
			background-color: #168db2;
			border-radius: 0.1vh;
			border: none;
			margin-left: 1vw;
		}
	}
</style>
